body {
  margin: 0px;
}
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: nowrap column;
  overflow:hidden;
}

.panel {    
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  background: lightblue;
  min-height: 50px;
  height: 100%;
}
.footer {
  background: lightblue;
  height: 100%;
  display:flex;
  flex-flow: column nowrap;
  align-items: stretch;
  text-align: center;
  justify-content: flex-start;
  overflow: hidden;
}
.footerArea{
  flex-grow: 1;
  display: flex;
  min-height: 100px;
  flex-flow: column;
  overflow-y: auto;
}
.footerAreaContent {
  min-height: 500px;
}
.footerBottomBar {
  background: blue;
  color: white;
  padding: 10px
}
.body {
  background: '#f5f5f5' !important;
  flex-grow: 3;
  display: flex;
  flex-flow: row nowrap;
}

.sideBarResizeContainer {
  width: 30%;
}
.sidebar {
  background: lightpink;
  min-width: 200px;
  width: 400px;
  width:100%;
  box-sizing: border-box;
  text-align: center;
  flex-grow: 1;
}

.withMargin {
  margin: 10px;
  box-sizing: border-box;
}

.content {
  flex-grow: 2;  
  background: white; 
}

.customHandle {
  cursor: ew-resize;
  width: 10px;
  height: 100px;
  margin: 0px -5px;
  background: lightblue;
  border: 2px solid gray;
  border-radius: 2px;
  text-align: center;
  z-index: 99999;
  overflow: hidden;
  display: flex;
  align-items: center ;
}

.customResizeBorder {
  cursor: ew-resize;
  width: 5px;
  background: gray;
  display: flex;
  z-index: 99999;
  align-items: center ;
  align-content: center ;
  justify-content: center;
  overflow: visible;

}